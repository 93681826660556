<template>
  <en-drawer :model-value="modelValue" title="选择单据" @close="$emit('update:model-value', false)" direction="btt" size="500">
    <flex-box>
      <template #default="{ height }">
        <table-dynamic
          code="STFOFD"
          :height="height"
          :data="table.data"
          :loading="table.loading"
          :paging="table.paging"
          :method="table.get"
          pagination
          :config="table.config"
        >
          <template #OPERATION="{ row }: { row: EnocloudInventoryDefinitions['StockTransferOutQueryDto'] }">
            <en-button link type="primary" @click="table.operation.all.click(row)">全部配件入库</en-button>
            <en-button link type="primary" @click="table.operation.batch.click(row)">拆分配件入库</en-button>
          </template>
        </table-dynamic>
      </template>
    </flex-box>
  </en-drawer>

  <en-dialog v-model="detail.visible" title="选择单据">
    <table-dynamic
      code="STFODLFD"
      :data="detail.form.data.stockTransferOutGoods"
      :loading="detail.form.loading"
      @selection-change="detail.form.stockTransferOutGoods.selection.change"
      show-selectable
      :config="{
        OPERATION: { visible: false },
        NO: { visible: false }
      }"
    ></table-dynamic>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="detail.footer.confirm.click">确定</en-button>
    </template>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean as PropType<boolean>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    'selection-change': (
      data: EnocloudInventoryDefinitions['StockTransferOutDto'],
      value: EnocloudInventoryDefinitions['StockTransferOutGoodsDto'][]
    ) => true
  },

  config: {
    children: {
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/stock/transfer/out/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { statusCode: 'A' }
            }
          }
        },
        config: {},
        children: {
          operation: {
            all: {
              async click(row: EnocloudInventoryDefinitions['StockTransferOutQueryDto']) {
                const res = await this.ajax('GET /enocloud/stock/transfer/out/:stockTransferOutId', { paths: [row.id] })
                this.emit('selection-change', res.data[0], res.data[0].stockTransferOutGoods)
                this.emit('update:model-value', false)
              }
            },
            batch: {
              click(row: EnocloudInventoryDefinitions['StockTransferOutQueryDto']) {
                this.detail.form.init()
                this.detail.form.data.id = row.id
                this.detail.form.get()
                this.detail.visible = true
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            ajax: {
              get: {
                action: 'GET /enocloud/stock/transfer/out/:stockTransferOutId',
                data: 'object',
                loading: true,
                init: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              }
            },
            children: {
              stockTransferOutGoods: {
                selection: {
                  data: [] as EnocloudInventoryDefinitions['StockTransferOutGoodsDto'][],
                  change(rows: EnocloudInventoryDefinitions['StockTransferOutGoodsDto'][]) {
                    this.detail.form.stockTransferOutGoods.selection.data = rows
                  }
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              click() {
                this.emit('selection-change', this.detail.form.data, this.detail.form.stockTransferOutGoods.selection.data)
                this.emit('update:model-value', false)
                this.detail.footer.cancel.click()
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
